import linkifyHtml from 'linkifyjs/html';
import { UTM_CONSTANTS } from '../constants/utmConstants';
import EASYCHAT_CONFIG from "../config/config";

function preventDefault(e) {
  e = e || window.event;
  if (e.preventDefault)
    e.preventDefault();
  e.returnValue = false;
}

function preventDefaultForScrollKeys(e) {
  let keys = {37: 1, 38: 1, 39: 1, 40: 1};

  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
}

export function disableScroll() {
  if (window.addEventListener) // older FF
    window.addEventListener('DOMMouseScroll', preventDefault, { passive: false });
  window.onwheel = preventDefault; // modern standard
  window.onmousewheel = document.onmousewheel = preventDefault; // older browsers, IE
  window.ontouchmove = preventDefault; // mobile
  document.onkeydown = preventDefaultForScrollKeys;
}

export function enableScroll() {
  if (window.removeEventListener)
    window.removeEventListener('DOMMouseScroll', preventDefault, false);
  window.onmousewheel = document.onmousewheel = null;
  window.onwheel = null;
  window.ontouchmove = null;
  document.onkeydown = null;
}

export function hasClass(ele, cls) {
  return ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
}

export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    let reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
    ele.className = ele.className.replace(reg, ' ');
  }
}

export function getEasychatCookie(name) {
  let v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  return v ? v[2] : null;
}

export function getCookie(cname) {
  let name = cname + "=";

  /*
    有遇過客戶的 cookie 裡有 % 造成 decodeURIComponent error 所以改成不特別 decode
    若有需要 decode 可以 get 完之後再 decode
  */
  // let decodedCookie = decodeURIComponent(document.cookie);
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
}

export function setCookie(cname, cvalue, exdays) {
  let d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function deleteCookie(name) {
  setCookie(name, "", -100);
}

export function getJsonFromUrl() {
  let query = location.search.substr(1);
  let result = {};
  query.split("&").forEach(function (part) {
    let item = part.split("=");
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
}

export function getYoutubeVideoId (url) {
  var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  var match = url.match(regExp);
  var videoId = '';

  if (match && match[2].length == 11) {
    videoId = match[2];
  } else {
    videoId = '';
  }
  return videoId;
}

export function linkifyAndFormatWelcomeMessage (message, isAddUTM) {
  let formatMessage = message;
  let linkifyOptions = {
    formatHref: function (href, type) {
      if (type === 'url' && isAddUTM) {
        href = addEasychatUTM(href, UTM_CONSTANTS.SOURCE.OMNICHAT, UTM_CONSTANTS.MEDIUM.WELCOME_NESSAGE, UTM_CONSTANTS.CAMPAIGN.WELCOME_MESSAGE);
      }
      return href;
    }
  };

  formatMessage = formatMessage.replace(new RegExp('\r?\n', 'g'), '<br />')

  return linkifyHtml(formatMessage, linkifyOptions);
}

export function linkifyAndFormatRemarketingMessage (message, campaignName, isAddUTM) {
  let formatMessage = escapeHTML(message);
  let linkifyOptions = {
    formatHref: function (href, type) {
      if (type === 'url' && isAddUTM) {
        href = addEasychatUTM(href, UTM_CONSTANTS.SOURCE.OMNICHAT, UTM_CONSTANTS.MEDIUM.REMARKETING_WEBCHAT, `${UTM_CONSTANTS.CAMPAIGN.REMARKETING_WEBCHAT}_${campaignName}`);
      }
      return href;
    }
  };

  formatMessage = formatMessage.replace(new RegExp('\r?\n', 'g'), '<br />')

  return linkifyHtml(formatMessage, linkifyOptions);
}

export function addEasychatUTM (url, source, medium , campaign) {
  if (url.indexOf('utm_source') !== -1) return url;

  let utmString = `utm_source=${source}&utm_medium=${medium}&utm_campaign=${campaign}`;

  if (url.indexOf('?') === -1) {
    url += '?';
  } else {
    url += '&';
  }
  url += utmString;
  return url;
}

export function doUserMappingFromMessagingChannel (queryParaJson, ecMemberId, features) {
  if (queryParaJson.ecch && queryParaJson.eccid && queryParaJson.ecuid) {
    let payload = {
      queryParamJson: queryParaJson,
      ecMemberId: ecMemberId
    };

    let iframe = document.getElementById('easychat-chat-dialog-iframe');
    iframe.contentWindow.postMessage(JSON.stringify({'in_app_browser_user_mapping': payload}), '*');

    let mappingParams = ['ecch', 'eccid', 'ecuid', 'ectag'];
    let resultURL = location.href;
    mappingParams.forEach((param) => {
      resultURL = removeURLParameter(resultURL, param)
    });

    let pushStateUrl = `${resultURL}&ec-mapping-processed=true`

    // 要使用 insider 功能團隊，將 ecuid 換成 isuid
    const isInsiderFeature = features.includes('insider_auto_social_binding_uid')
    if(isInsiderFeature) {
      const ecuid = queryParaJson?.ecuid ? queryParaJson?.ecuid : null
      pushStateUrl += `&isuid=${ecuid}`
    }

    history.pushState(null, '', pushStateUrl);
  }
}

export function sendEcOrderIdToIframe (ecPartner) {
  let ecOrderId = null;
  switch (ecPartner) {
    case EASYCHAT_CONFIG.PARTNER_91APP:
      ecOrderId = document.querySelector('#tradesOrderGroup_Code') ? document.querySelector('#tradesOrderGroup_Code').textContent : null;
      break;
    case EASYCHAT_CONFIG.PARTNER_WACA:
      // Get order Id from omnichatTracker array
      if (window.omnichatTracker && window.omnichatTracker.length > 0) {
        console.log('get order id from omnichatTracker array');
        for (let i = 0; i < window.omnichatTracker.length; i++) {
          if (window.omnichatTracker[i][1] === 'purchase') {
            ecOrderId = window.omnichatTracker[i][2].transaction_id;
            break;
          }
        }
      } else {
        // 從 Waca 結帳完成頁面的 DOM 取得訂單編號
        ecOrderId = document.querySelector('.orderfinish_area .txt_ordernum b') ? document.querySelector('.orderfinish_area .txt_ordernum b').textContent : null;
      }
      break;
  }

  if (ecOrderId) {
    let iframe = document.getElementById('easychat-chat-dialog-iframe');
    iframe.contentWindow.postMessage(JSON.stringify({'ec_order_id_for_subscriber': ecOrderId}), '*');
  }
}

export function removeURLParameter(url, parameter) {
  //prefer to use l.search if you have a location/link object
  let urlparts = url.split('?');
  if (urlparts.length >= 2) {

    let prefix = encodeURIComponent(parameter) + '=';
    let pars = urlparts[1].split(/[&;]/g);

    //reverse iteration as may be destructive
    for (let i = pars.length; i-- > 0;) {
      //idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }

    return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
  }
  return url;
}

export function isMobileDevice () {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

export function createElementFromHTMLString (htmlString) {
  var div = document.createElement('div');
  div.innerHTML = htmlString.trim();

  // Change this to div.childNodes to support multiple top-level nodes
  return div.firstChild;
}

export function b64EncodeUnicode(str) {
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
    return String.fromCharCode(parseInt(p1, 16))
  }))
}

export function utoa(str) {
  return btoa(encodeURIComponent(str));
}

export function atou(str) {
  return decodeURIComponent(atob(str));
}

export function escapeHTML(text) {
  // return text;
  var map = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#039;'
  };

  return text.replace(/[&<>"']/g, function (m) {
    return map[m];
  });
}