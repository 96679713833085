import { createElementFromHTMLString, setCookie, getCookie, b64EncodeUnicode, isMobileDevice } from "../utils";
import EASYCHAT_CONFIG from '../config/config';
import '../helpers/qrcode';
import whatsappIconSrc from '../images/whatsapp_logo.png';
import whatsappWhiteIconSrc from '../images/whatsapp_logo_with_white_border.png';
import lineIconSrc from '../images/line_logo.png';
import lineWhiteIconSrc from '../images/line_logo_with_white_border.png'

// TODO: 已經按過綁定好像還是要紀錄
// TODO: 整理 optinModal 跟 social subscriber
class OptinModal {
  constructor(optinData, appLang) {
    this.teamName = optinData.teamName;
    this.currentUserName = optinData.currentUserName; // current user omnichat id
    this.title = optinData.title;
    this.body = optinData.body;
    this.platform = optinData.platform;
    this.optinStyle = optinData.style; // message_bubble / modal
    this.optinChannelId = optinData.channelId;
    this.displayLimit = optinData.displayLimit; // one day display limit
    this.addToCartDelay = optinData.addToCartDelay // show optin until add_to_cart event amount
    this.fbAppId = EASYCHAT_CONFIG.SOCIAL_SUBSCRIBER_APP_ID;
    this.oaId = optinData.oaId // LINE OA ID
    this.buttonTitle = optinData.buttonTitle // LINE optin button title
    this.successMessage = optinData.successMessage;
    this.whatsappNumber = optinData.whatsappNumber;
    this.ecMemberId = optinData.ecMemberId;
    this.optinModalMaskDOM = null;
    this.optinModalWrapperDOM = null;
    this.appLang = appLang;
    this.liffId = optinData.bindingLiffId
    this.CONSTANT = {
      TEXT: {
        CLICK_INTO_LINE: '按此進入 LINE，並輸入 <span style="text-decoration: underline">{{sixDigit}}</span>以完成綁定',
        SCAN_QRCODE: '請掃描 QRCode 並在聊天室傳送綁定號碼',
        CLICK_INTO_WHATSAPP: '按此進入 WhatsApp，並輸入 <span style="text-decoration: underline">{{sixDigit}}</span>以完成綁定',
        ENTER_LINE_CHAT_ROOM_INPUT_DIGIT_TITLE_LIFF: '按此進入以完成綁定',
        SCAN_QRCODE_LIFF: "請掃描 QRCode"
      },
      LANG: {
        EN: 'en',
        ZH_HANT: 'zh-Hant'
      }
    }

    this.setLanguageText();
    this.createOptinModal();
    this.initEventListener();
  }

  setLanguageText () {
    if (this.appLang === this.CONSTANT.LANG.ZH_HANT) {
      this.CONSTANT.TEXT = {
        CLICK_INTO_LINE: '按此進入 LINE，並輸入 <span style="text-decoration: underline">{{sixDigit}}</span>以完成綁定',
        SCAN_QRCODE: '請掃描 QRCode 並在聊天室傳送綁定號碼',
        CLICK_INTO_WHATSAPP: '按此進入 WhatsApp，並輸入 <span style="text-decoration: underline">{{sixDigit}}</span>以完成綁定',
        ENTER_LINE_CHAT_ROOM_INPUT_DIGIT_TITLE_LIFF: '按此進入以完成綁定',
        SCAN_QRCODE_LIFF: "請掃描 QRCode"
      }
    } else if (this.appLang === this.CONSTANT.LANG.EN) {
      this.CONSTANT.TEXT = {
        CLICK_INTO_LINE: 'Click here and input <span style="text-decoration: underline">{{sixDigit}}</span> to enter LINE chat room.',
        SCAN_QRCODE: 'Scan QRcode and send 6-digit number in the chat room',
        CLICK_INTO_WHATSAPP: 'Click here and input <span style="text-decoration: underline">{{sixDigit}}</span> to enter WhatsApp chat room.',
        ENTER_LINE_CHAT_ROOM_INPUT_DIGIT_TITLE_LIFF: 'Click here to bind',
        SCAN_QRCODE_LIFF: "Please Scan QRcode"
      }
    }
  }

  initEventListener() {
    let that = this;
    window.addEventListener('show_cart_optin', function (e) {
      that.showOptinModalOrPopUp();
    });

    this.optinModalMaskDOM.addEventListener('click', function () {
      that.closeOptinModal();
    });

    document.querySelector('.modal-close-button').addEventListener('click', function () {
      that.closeOptinModal();
    });

    document.querySelector('.omni-modal-dialog').addEventListener('click', function (e) {
      e.stopPropagation();
    });

    if (this.platform === 'line') {
      let iframe = document.getElementById('easychat-chat-dialog-iframe');
      let that = this;

      document.getElementById('abandoned-cart-link-line-button').addEventListener('click', function () {
        let payload = {
          'get_line_link_number_request': {
            orderId: '',
            memberId: '',
            teamName: that.teamName,
            optinMessageType: 1,  // 因為後台設定裡尚未支援此設定，所以先預設是都傳 1 (表 optinMessage 為純文字)
            optinMessage: that.successMessage,
            optinType: 'add_cart'
          }
        };
        iframe.contentWindow.postMessage(JSON.stringify(payload), '*');
      });

      window.addEventListener('message', function (event) {
        if (event.origin !== EASYCHAT_CONFIG.IFRAME_HTML_SOURCE) {
          return; 
        }
        let data = '';
        try {
          data = JSON.parse(event.data);
        } catch (e) {
          return false;
        }

        if (data['get_line_link_number_response']) {
          let sixDigit = data['get_line_link_number_response'];
          let link = `https://line.me/R/oaMessage/@${that.oaId}/?${sixDigit}`
          if(that.liffId) {
            link = `https://liff.line.me/${that.liffId}/?liffId=${that.liffId}&ecId=${that.currentUserName}&channelId=${that.optinChannelId}&memberId=${that.ecMemberId}&oaId=${that.oaId}&optinType=add_cart`
          }
          document.getElementById('abandoned-cart-link-line-button').style.display = 'none';

          if (isMobileDevice()) {
            let lineAddFriendBtnInnerHTML = that.liffId? that.CONSTANT.TEXT.ENTER_LINE_CHAT_ROOM_INPUT_DIGIT_TITLE_LIFF : that.CONSTANT.TEXT.CLICK_INTO_LINE.replace('{{sixDigit}}', sixDigit);
            let lineAddFriendBtn = document.getElementById('line-add-friend-btn');
            lineAddFriendBtn.style.display = 'block';
            lineAddFriendBtn.innerHTML = lineAddFriendBtnInnerHTML;
            lineAddFriendBtn.href = link;
          } else {
            document.querySelector('.omni-modal-wrapper .line-optin-instruction').style.display = 'block';
            if(!that.liffId) document.getElementById('abandoned-cart-line-six-digit').innerHTML = sixDigit;

            if (document.querySelector('#qrcode').innerHTML === '') {
              let qrCode = new OMNI_QRCODE('qrcode', {
                width: 128 * 5,
                height: 128 * 5,
                correctLevel : OMNI_QRCODE.CorrectLevel.Q
              });
              qrCode.makeCode(link);
              qrCode.addLogo('qrcode', lineWhiteIconSrc)
            }
          }
        }
      }, false)
    }

    if (this.platform === 'facebook') {
      this.initFacebookSDK();
    }

    if (this.platform === 'whatsapp') {
      let iframe = document.getElementById('easychat-chat-dialog-iframe');
      let that = this;

      document.getElementById('abandoned-cart-link-whatsapp-button').addEventListener('click', function () {
        let payload = {
          'get_whatsapp_link_number_request': {
            orderId: '',
            memberId: '',
            teamName: that.teamName,
            optinMessageType: 1,  // 因為後台設定裡尚未支援此設定，所以先預設是都傳 1 (表 optinMessage 為純文字)
            optinMessage: that.successMessage,
            optinType: 'add_cart'
          }
        };
        iframe.contentWindow.postMessage(JSON.stringify(payload), '*');
      });

      window.addEventListener('message', function (event) {
        if (event.origin !== EASYCHAT_CONFIG.IFRAME_HTML_SOURCE) {
          return; 
        }
        let data = '';
        try {
          data = JSON.parse(event.data);
        } catch (e) {
          return false;
        }

        if (data['get_whatsapp_link_number_response']) {
          let sixDigit = data['get_whatsapp_link_number_response'];
          let phoneNumber = that.whatsappNumber.charAt(0) === '+' ? that.whatsappNumber.substr(1) : that.whatsappNumber;
          let link = `https://wa.me/${phoneNumber}?text=${sixDigit}`
          document.getElementById('abandoned-cart-link-whatsapp-button').style.display = 'none';

          if (isMobileDevice()) {
            let whatsappAddFriendBtnInnerHTML = that.CONSTANT.TEXT.CLICK_INTO_WHATSAPP.replace('{{sixDigit}}', sixDigit);
            let whatsappAddFriendBtn = document.getElementById('whatsapp-add-friend-btn');
            whatsappAddFriendBtn.style.display = 'block';
            whatsappAddFriendBtn.innerHTML = whatsappAddFriendBtnInnerHTML;
            whatsappAddFriendBtn.href = link;
          } else {
            document.querySelector('.omni-modal-wrapper .whatsapp-optin-instruction').style.display = 'block';
            document.getElementById('abandoned-cart-whatsapp-six-digit').innerHTML = sixDigit;

            if (document.querySelector('#qrcode').innerHTML === '') {
              let qrCode = new OMNI_QRCODE('qrcode', {
                width: 128 * 5,
                height: 128 * 5,
                correctLevel : OMNI_QRCODE.CorrectLevel.Q
              });
              qrCode.makeCode(link);
              qrCode.addLogo('qrcode', whatsappWhiteIconSrc)
            }
          }
        }
      }, false)
    }
  }

  showOptinModalOrPopUp() {
    // 檢查一天出現過的次數 cookie 檢查
    // 檢查 add_to_cart 次數是否達標
    let that = this;
    let showedCounts = parseInt(getCookie('cart_optin_show_times'));
    let addToCartAmount = parseInt(getCookie('add_to_cart_times'));
    let alreadyOptin = parseInt(getCookie('is_clicked_cart_optin'));
    let hasPhoneNumber = parseInt(getCookie('has_phone_number'));
    let alreadyGetLineSixDigit = window.alreadyGetLineSixDigit;
    let alreadyGetWhatsappSixDigit = window.alreadyGetWhatsappSixDigit;

    if (!addToCartAmount || addToCartAmount < this.addToCartDelay) return;
    if (showedCounts >= this.displayLimit) return;
    if (alreadyOptin === 1) return;
    if (alreadyGetLineSixDigit) return; // 訂閱插件那邊已經 get 過六位數號碼的情況

    if (this.platform === 'whatsapp') {
      if (alreadyGetWhatsappSixDigit) return; // 訂閱插件那邊已經 get 過六位數號碼的情況
      else if (hasPhoneNumber === 1) return; // cookie 那邊已經有 pixel 電話號碼的情況
    };

    if (this.optinStyle === 'message_bubble') {

    } else {
      // show modal
      setTimeout(function () {
        that.openOptinModal();
      }, 3000);
    }
  }

  // TODO: need to test SPA
  openOptinModal() {
    // 如果是 QDM in mobile，要避免 optinModal 被擋住
    if (window.screen.width <= 567 && document.querySelector('.slidePanel')) {
      document.querySelector('.slidePanel').style.zIndex = '99999999'
    }

    // 如果社群訂閱按鈕有顯示，則先隱藏社群訂閱按鈕再 show abandoned cart optin modal
    this.checkAndRemoveSocialSubscriber()

    // show abandoned cart optin modal
    this.optinModalWrapperDOM.classList.add('active');

    let showedCounts = getCookie('cart_optin_show_times');
    if (showedCounts) {
      // TODO: expired day need to consider
      setCookie('cart_optin_show_times', parseInt(showedCounts) + 1, 1);
    } else {
      setCookie('cart_optin_show_times', 1, 1);
    }
  }

  checkAndRemoveSocialSubscriber() {
    let blockHTML = document.getElementById('social-subscriber-block');
    if (blockHTML) {
      blockHTML.classList.remove('easychat-block-show');
      blockHTML.classList.add('easychat-block-hidden');
    }
  }

  // TODO: close modal adjust animation
  closeOptinModal() {
    this.optinModalWrapperDOM.classList.remove('active');
  }

  createOptinModal() {
    document.body.append(createElementFromHTMLString(`
      <div class="omni-modal-wrapper">
        <div class="omni-optin-modal-mask">
          <div class="omni-modal-dialog">
            <div class="modal-close-button"></div>
            <div class="modal-title">
              <h3>${ this.title}</h3>
            </div>
            <div class="modal-body">${ this.body}</div>
            ${this.renderCartOptin()}
          </div>
        </div>
      </div>
    `))
    this.optinModalMaskDOM = document.querySelector('.omni-optin-modal-mask');
    this.optinModalWrapperDOM = document.querySelector('.omni-modal-wrapper');
  }

  renderCartOptin() {
    if (this.platform === 'facebook') {
      let dataRef = b64EncodeUnicode(JSON.stringify({
        teamName: this.teamName, // from chat iframe
        orderId: '',
        memberId: '',
        easychatUserId: this.currentUserName,
        optinMessage: this.successMessage,
        optinMessageType: 1, // 因為後台設定裡尚未支援此設定，所以先預設是都傳 1 (表 optinMessage 為純文字)
        optinType: 'add_cart'
      }));
      return `
        <div class="fb-optin-plugin">
          <div class="fb-send-to-messenger" 
                style="width: 196px;padding: 0"
                messenger_app_id="${this.fbAppId}"
                page_id="${this.optinChannelId}"
                data-ref="${dataRef}"
                color="blue"
                cta_text="RECEIVE_THIS_IN_MESSENGER"
                size="xlarge"
          </div>
        </div>
      `
    } else if (this.platform === 'line') {
      // for LINE
      return `
        <div class="line-optin-plugin">
          <button id="abandoned-cart-link-line-button">
            <img src="${lineIconSrc}" alt="" class="line-logo">
            ${this.buttonTitle}
          </button>
          <div id="qrcode"></div>
          <div class="line-optin-instruction">${this.liffId? this.CONSTANT.TEXT.SCAN_QRCODE_LIFF : this.CONSTANT.TEXT.SCAN_QRCODE}</div>
          ${this.liffId ? '': '<div id="abandoned-cart-line-six-digit"></div>'}
          <a id="line-add-friend-btn" style="display: none"></a>
        </div>
      `
    } else if (this.platform === 'whatsapp')  {
      return `
        <div class="whatsapp-optin-plugin">
          <button id="abandoned-cart-link-whatsapp-button">
            <img src="${whatsappIconSrc}" alt="" class="whatsapp-logo">
            ${this.buttonTitle}
          </button>
          <div id="qrcode"></div>
          <div class="whatsapp-optin-instruction">${this.CONSTANT.TEXT.SCAN_QRCODE}</div>
          <div id="abandoned-cart-whatsapp-six-digit"></div>
          <a id="whatsapp-add-friend-btn" style="display: none"></a>
        </div>
      `
    }
  }

  setSubscribeEventForMessenger() {
    // LINE 不需要 because LINE 綁定身份之後就不會 show modal 了
    if (FB) {
      FB.Event.subscribe('send_to_messenger', function (e) {
        // callback for events triggered by the plugin
        if (e.event === 'clicked') {
          setCookie('is_clicked_cart_optin', 1);
        }
      });
    }
  }

  initFacebookSDK() {
    const that = this

    if (typeof window.FB !== 'undefined') {
      FB.init({
        appId: this.fbAppId,
        cookie: true,  // enable cookies to allow the server to access
        // the session
        xfbml: true,  // parse social plugins on this page
        version: 'v20.0'
      })

      that.setSubscribeEventForMessenger();
    } else {
      window.fbAsyncInit = function () {
        FB.init({
          appId: that.fbAppId,
          cookie: true,  // enable cookies to allow the server to access
          // the session
          xfbml: true,  // parse social plugins on this page
          version: 'v20.0'
        });

        that.setSubscribeEventForMessenger();
      };

      // Load the SDK asynchronously
      (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/zh_TW/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    }
  }

}

export default OptinModal;
