// update the cookie if it exists, if it doesn't, create a new one, lasting 2 years
// Cookie.exists('uid') ? Cookie.set('uid', Cookie.get('uid'), 2*365*24*60) : Cookie.set('uid', guid(), 2*365*24*60);
// save any utms through as session cookies
import Cookie from './cookie';
import Config from './config';
import Constants from './constants';
import Pixel from './pixel';
import helpers from './helpers';
import Url from './url';
import AbandonedCart from "./abandoned-cart";
import EASYCHAT_CONFIG from '../config/config';

var setup = function(config) {

  var tracker = window['omnichatTracker'];

  if (!tracker) {
    tracker = window['omnichatTracker'] = [];
  }

  // config the tracker
  var initAttrs = {
    shopId: (data) => data.shopId,
    shopPlatform: (data) => data.shopPlatform,
    team: (data) => data.team,
    enablePixel: (data) => data.enablePixel,
    ecid: (data) => data.ecid,
    salesIdLifetime: (data) => data.txLinkTrackingLifetime,
  }

  for (var index in initAttrs) {
    if (initAttrs.hasOwnProperty(index)) {
      var param = initAttrs[index](config);
      if (helpers.isset(param)) {
        Config[index] = param;
      }
    }
  }

  // Url.extractUtms();

  // set utm
  Cookie.setUtms();
  // store sale user id
  Cookie.setSaleId();
  // store track id
  Cookie.setTrackId();

  // if (config.shopPlatform == '91App') {
    config.queue.unshift(['send', 'pageview']);
  // }

  /** get 91app member data & device Id from cookie **/
  if (config.shopPlatform == '91App'
      && config.enablePixel
      && config.shopId) {
    // console.log('omnichat -> track 91app setup');
    var memberId = Cookie.get('MID');
    var deviceId = Cookie.get('gaClientId');
    // memberId = 4112;
    // deviceId = '8e518b91-fd25-4a29-92a7-bc4db48a7b8a1';
    Config.memberId = memberId;
    Config.deviceId = deviceId;

    if (Config.deviceId) {

      var currentMemberId = memberId ? memberId : '_';
      var currentMemberMapping = helpers.hash(currentMemberId+'.'+deviceId);
      var prevMemberMapping = false;

      if (Cookie.exists(Constants.cookie.memberMapping)) {
        prevMemberMapping = Cookie.get(Constants.cookie.memberMapping);
      }

      // only if the device ID or member ID change will fire member mapping
      if (currentMemberMapping != prevMemberMapping) {

        // console.log('omnichat -> track 91app member mapping');
        config.queue.unshift(['event', '91app_member_mapping']);

        Cookie.set(Constants.cookie.memberMapping, currentMemberMapping, EASYCHAT_CONFIG.SESSION_LIFETIME);
      }
    }
  }

  /** For 91App add_to_cart event **/
  if (config.shopPlatform === '91App' && config.enablePixel) {
    // For product detail Page add_to_cart button
    // For product list page add_to_cart button
    document.body.addEventListener('click', function (e) {
      const targetClasses = ['add-to-cart-btn', 'btn-add-cart'];

      for (let className of targetClasses) {
        if ((e.target && e.target.classList.contains(className)) ||
            (e.target && e.target.parentNode.classList.contains(className))) {
          AbandonedCart.handleAddToCartEvent();
          break;
        }
      }
    })

    // For product category page add to cart
    document.body.addEventListener('click', function (e) {
      const targetClasses = ['sc-irTswW', 'sc-csCMJt', 'sc-gjTGSA', 'sc-jMAIzZ'];

      for (let className of targetClasses) {
        if ((e.target && e.target.classList.contains(className)) ||
            (e.target && e.target.parentNode.classList.contains(className))) {
          AbandonedCart.handleAddToCartEvent();
          break;
        }
      }
    });
  }

  // process the queue and future incoming commands
  tracker.process = function(command, action, optional) {
    // console.log(command);
    // console.log(action);
    // console.log(optional);

    var supportedCmds = Constants.supportedCmds;

    // only process the supported cmds
    if (supportedCmds.indexOf(command) === -1) {
      console.warn('[Omnichat Tracker] Unsupported tracking command [' + command + '].');
      return;
    }

    var supportedActions = Constants.supportedActions[command];
    // only process the supported actions
    if (supportedActions.indexOf(action) === -1) {
      console.warn('[Omnichat Tracker] Unsupported tracking action [' + action + '].');
      return;
    }

    if(command == 'event' || command == 'send') {

      new Pixel(action, helpers.now(), optional);

      // For abandoned cart
      // TODO: need to deal with 91APP, use same logic and addEventListener to 91App add_to_cart button
      // if command === add_to_cart trigger open abandoned optin event
      if (action === 'add_to_cart') {
        AbandonedCart.handleAddToCartEvent();
      }

    } else if (command == 'init') {

      // config the tracker
      var initAttrs = {
        memberId: (data) => data.memberId,
        // memberEmail: (data) => data.memberEmail,
        // memberPhone: (data) => data.memberPhone
      }

      for (var index in initAttrs) {
        if (initAttrs.hasOwnProperty(index)) {
          var param = initAttrs[index](optional);
          if (helpers.isset(param)) {
            Config[index] = param;
          }
        }
      }

      // store a flag in cookie if it has phone number
      // if yes, then disable whatsapp abandoned cart optin popup in OptinModal.js
      let hasPhoneNumber = Cookie.get('has_phone_number');
      hasPhoneNumber = optional.memberPhone ? 1 : 0;
      Cookie.set('has_phone_number', hasPhoneNumber, EASYCHAT_CONFIG.SESSION_LIFETIME);

      /** only fire member mapping event when data changed **/
      var memberInfo = [];
      memberInfo.push(optional.memberId ? optional.memberId : '_');
      memberInfo.push(optional.memberEmail ? optional.memberEmail : '_');
      memberInfo.push(optional.memberPhone ? optional.memberPhone : '_');
      memberInfo.push(optional.memberName ? optional.memberName : '_');

      var currentMemberMapping = helpers.hash(memberInfo.join('.'));
      var prevMemberMapping = false;

      if (Cookie.exists(Constants.cookie.memberMapping)) {
        prevMemberMapping = Cookie.get(Constants.cookie.memberMapping);
      }

      if (currentMemberMapping != prevMemberMapping) {
        new Pixel('member_mapping', helpers.now(), optional);
        Cookie.set(Constants.cookie.memberMapping, currentMemberMapping, EASYCHAT_CONFIG.SESSION_LIFETIME);
      }

    }
  }

  tracker.push = function(data) {
    tracker.process.apply(tracker, data);
  }

  // run the queued calls from the snippet to be processed
  for (var i = 0, l = config.queue.length; i < l; i++) {
    tracker.process.apply(tracker, config.queue[i]);
  }

  // window.addEventListener('unload', function() {
    // if (!Config.pageCloseOnce) {
      // Config.pageCloseOnce = true;
      // set 10 minutes page close cookie
      // Cookie.throttle('pageclose');
      // new Pixel('pageclose', now(), function(){
      //   // if a link was clicked in the last 5 seconds that goes to an external host, pass it through as event data
      //   if (isset(Config.externalHost) && (now() - Config.externalHost.time) < 5*1000) {
      //     return Config.externalHost.link;
      //   }
      // });
    // }
  // });

  // window.onload = function() {
    // var aTags = document.getElementsByTagName('a');
    // for (var i = 0, l = aTags.length; i < l; i++) {
    //   aTags[i].addEventListener('click', function(e) {
    //     if (Url.externalHost(this)) {
    //       Config.externalHost = {link:this.href, time:now()};
    //     }
    //   }.bind(aTags[i]));
    // }
  // }

}

export default setup;
